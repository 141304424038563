import React from "react";

import { useHistory } from "react-router-dom";
import { logo } from "../../resources/js/images";
import AuthWrapper from "../signupAdmin/AuthWrapper";
import LoginAdminPage from "./LoginPage";

const LoginAdmin = () => {
    const history = useHistory();
    return (
        <AuthWrapper>
            <div className="bg-black-600 min-h-screen md:bg-transparent flex justify-center items-center h-full w-full ">
                <div className="bg-white min-h-screen md:min-h-[300px] mt-3 pt-3 px-2  md:p-1 box-border lg:px-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    <div
                        role="button"
                        className=" hidden md:flex justify-center items-center"
                        onClick={() => history.push("/")}
                    >
                        <img src={logo} alt="logo" className="w-6 h-3" />
                    </div>
                    <LoginAdminPage />
                </div>
            </div>
        </AuthWrapper>
    );
};

export default LoginAdmin;
