import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { logo } from "../../resources/js/images";
import AuthWrapper from "./AuthWrapper";
import SignUpPage from "./SignUpPage";
import WelcomePages from "./WelcomePages";

const SignUpAdmin = () => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    const history = useHistory();
    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return <WelcomePages handleSlide={handleTab} />;
            case 1:
                return <SignUpPage />;
            default:
                return null;
        }
    };
    return (
        <AuthWrapper
            heading="Get Started"
            text="Please create your account to get onboarded to our platform"
        >
            <div className=" hidden md:flex justify-center items-center h-full w-full">
                <div className=" bg-white pt-1 md:p-1 box-border h-full lg:p-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    <div
                        role="button"
                        className=" flex justify-center items-center"
                        onClick={() => history.push("/")}
                    >
                        <img src={logo} alt="logo" className="w-6 h-3" />
                    </div>
                    <SignUpPage />
                </div>
            </div>
            <div className="flex md:hidden justify-center items-center h-full w-full">
                <div className=" bg-white h-full pt-2 mt-4 md:p-1 box-border lg:p-3  flex flex-col gap-2 rounded-t-[3rem] md:rounded-xl  w-full ">
                    {renderTabContent()}
                </div>
            </div>
        </AuthWrapper>
    );
};

export default SignUpAdmin;
