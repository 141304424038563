import React from "react";
import cn from "../../../lib/cn";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";

const FundDetails = ({ label, data, className, children }) => {
    console.log(data, "lenght");
    return (
        <div className={cn(className)}>
            <div>
                <div className="bg-linear-button rounded-sm py-1.6 px-1">
                    {label && (
                        <div className="bg-white bg-opacity-20 rounded-sm">
                            <P className="text-white font-vietnam text-center py-0.8 mb-2 text-xs 2xs:text-sm ">
                                {label}
                            </P>
                        </div>
                    )}
                    <div className="flex justify-between">
                        {data.map((item, index) => (
                            <React.Fragment key={index}>
                                {index === 1 ? (
                                    <>
                                        <div className="w-px h-auto bg-white"></div>
                                        <SolarItem {...item} />
                                        <div className="w-px h-auto bg-white"></div>
                                    </>
                                ) : (
                                    <SolarItem {...item} />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    {children && children}
                </div>
            </div>
        </div>
    );
};

export default FundDetails;

const SolarItem = ({ name, value, className, id }) => {
    return (
        <div className={cn("text-center px-0.5 flex-1", className)}>
            <H4 className="text-white font-vietnam text-sm 2xs:text-base">
                {value}
            </H4>
            <P className=" text-white mt-0.2 text-xs 2xs:text-sm text-nowrap">
                {name}
            </P>
        </div>
    );
};
