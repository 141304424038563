import React, { useState } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import { REMOVE_REDIRECT_PATH } from "../../constants/contexConstant";
import { emailRegEx, phoneRegEx } from "../../constants/regularExpression";
import { LOGIN, SIGNUP_CONSUMER_ENDPOINT } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { handleRedirection } from "../../helpers/routingHelper";
import auth from "../../services/auth";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import LabeledInput from "../../shared/ui/Form/LabeledInput";
import OTPModal from "../../shared/ui/OTPModal";

const validateSignupForm = (formData, getOtp) => {
    const newErrors = {};
    if (!formData.Name.trim()) {
        newErrors.Name = "Name is required";
    }

    if (!formData.email.trim()) {
        newErrors.email = "Email is required";
    } else if (!emailRegEx.test(formData.email)) {
        newErrors.email = "Invalid Email";
    }
    if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone Number is required";
    } else if (!phoneRegEx.test(formData.phoneNumber)) {
        newErrors.phoneNumber = "Invalid Phone Number ";
    }
    if (!formData.flat) {
        newErrors.flat = "Flat Number is required";
    }
    if (!getOtp) {
        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
        }
    } else {
        if (!formData.otp.trim()) {
            newErrors.otp = "OTP is required";
        }
    }
    return newErrors;
};

const SignUpAdminPage = ({ handleLogIn }) => {
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [formData, setFormData] = useState({
        Name: "",
        email: "",
        phoneNumber: "",
        password: "",

        otp: "",
        flat: "",
    });

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: "" });
    };

    const handleError = (validationErrors) => {
        setErrors(validationErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateSignupForm(formData, otp);

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            try {
                let reqData = {
                    name: formData.Name,
                    email: formData.email,
                    phone: `91${formData.phone}`,
                    referralCode: null,
                    password: formData.password,
                    otp,
                };
                let endpoint = SIGNUP_CONSUMER_ENDPOINT;
                await request.post({
                    endpoint,
                    body: reqData,
                });

                await auth.login({
                    username: formData.email,
                    password: formData.password,
                    otpEnable: false,
                });

                const profile = await auth.getUserProfile(dispatch);
                let status = profile?.data?.investor_profile?.plant?.status;
                const roles = profile?.data?.investor_profile?.roles;

                handleRedirection(state, history, roles, status);

                dispatch({
                    type: REMOVE_REDIRECT_PATH,
                });
                setLoading(false);

                handleLogIn();
            } catch (err) {
                setLoading(false);
                toast.error(err || ERROR_MSG);
            }
        }
    };
    const [otp, setOtp] = useState(false);
    return (
        <div className="px-1">
            <div className="flex flex-col items-start gap-1 mb-3">
                <H4 className="font-semibold">Create your account</H4>
                <p className="text-gray-secondary text-xs">Rohan Ananta CHS</p>
            </div>
            <div className="flex flex-col gap-1">
                <LabeledInput
                    label={"Name"}
                    value={formData?.Name}
                    onChange={handleChange}
                    name="Name"
                    type={"text"}
                    errorMsg={errors?.Name}
                    required={true}
                />
                <LabeledInput
                    label={"Mobile"}
                    value={formData?.phoneNumber}
                    onChange={handleChange}
                    name={"phoneNumber"}
                    type={"text"}
                    errorMsg={errors?.phoneNumber}
                    required={true}
                />
                <LabeledInput
                    label={"Email"}
                    value={formData?.email}
                    onChange={handleChange}
                    name={"email"}
                    required={true}
                    type={"text"}
                    errorMsg={errors?.email}
                />
                <LabeledInput
                    label="New password"
                    inputWrapper="rounded-l-xl w-full"
                    name="password"
                    required={true}
                    onChange={handleChange}
                    iconChange={() => setShow((prev) => !prev)}
                    type={show ? "text" : "password"}
                    icon={show ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    iconWrapperClass={
                        "  item-center flex justify-center py-[1.5rem] px-[2rem] text-xl rounded-r-xl bg-white "
                    }
                    errorMsg={errors?.password}
                />

                <LabeledInput
                    label={"Flat Number"}
                    value={formData?.flat}
                    onChange={handleChange}
                    required={true}
                    name={"flat"}
                    type={"text"}
                    errorMsg={errors?.flat}
                />
                <p className="text-gray-550 text-xs">
                    You can registered multiple accounts for the same flat, Feel
                    free to share with your friends and family*
                </p>

                <OTPModal
                    isOpen={otp}
                    onClose={() => setOtp(false)}
                    formData={formData}
                    errors={errors}
                    dispatch={dispatch}
                    setLoading={setLoading}
                    handleError={handleError}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
                <Button
                    className={
                        "bg-black text-white mt-1 !rounded-[3rem] w-full"
                    }
                    onClick={handleSubmit}
                >
                    Continue
                </Button>

                <div className="flex items-center justify-end mb-5">
                    <P className="flex items-center gap-0.2">
                        Already have account?
                        <span
                            className="text-blue underline"
                            role="button"
                            onClick={() => history.push(`/lease${LOGIN}`)}
                        >
                            Sign in
                        </span>
                    </P>
                </div>
            </div>
        </div>
    );
};

export default SignUpAdminPage;
