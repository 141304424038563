import React from "react";
import ModalAlt from "../../components/modal/ModalAlt";
import { validate } from "../../helpers/fieldValidation";
import { handleResentOTP } from "../../services/apiRequest";
import Button from "./Button";
import OTP from "./OTP";

const OTPModal = ({
    isOpen,
    onClose,
    formData,
    errors,
    handleError,
    handleChange,
    handleSubmit,
    setLoading,
    dispatch,
}) => {
    const resentOtpHandler = async (mode) => {
        const rules = {
            Name: { required: true },
            password: { required: true },
            email: { required: true, pattern: /\S+@\S+\.\S+/ },
            phoneNumber: { required: true, pattern: /^\d{10}$/ },
        };

        const validationErrors = validate(formData, rules);

        if (Object.keys(validationErrors).length > 0) {
            handleError(validationErrors);
        } else {
            let credentials = `91${formData.phoneNumber}`;
            await handleResentOTP(mode, credentials, setLoading, dispatch);
        }
    };
    return (
        <ModalAlt isOpen={isOpen} onClose={onClose} times={false}>
            <div className="p-2">
                <OTP
                    otp={formData.otp}
                    onChange={handleChange}
                    error={!!errors?.otp}
                    errorMessage={errors.otp}
                    handleResentOTP={resentOtpHandler}
                />
                <Button
                    className="bg-black text-white mt-1 !rounded-[3rem] w-full"
                    onClick={handleSubmit}
                >
                    Verify
                </Button>
            </div>
        </ModalAlt>
    );
};

export default OTPModal;
