import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../constants/routes";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import WelcomeScreen1 from "./WelcomeScreen1";
import WelcomeScreen2 from "./WelcomeScreen2";
import WelcomeScreen3 from "./WelcomeScreen3";
import WelcomeScreen4 from "./WelcomeScreen4";

const WelcomePages = ({ handleSlide }) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    const renderTabContent = () => {
        switch (activeTab) {
            case 0:
                return <WelcomeScreen1 />;
            case 1:
                return <WelcomeScreen2 />;
            case 2:
                return <WelcomeScreen3 />;
            case 3:
                return <WelcomeScreen4 />;

            default:
                return handleSlide(1);
        }
    };
    return (
        <div className="p-2">
            <div>{renderTabContent()}</div>
            <div className="flex justify-center items-center space-x-2 mb-2 xs:mb-4">
                {[0, 1, 2, 3].map((index) => (
                    <div
                        key={index}
                        className={`w-1 h-1 rounded-full cursor-pointer transition-colors duration-300 ${
                            activeTab === index ? "bg-gray-800" : "bg-gray-400"
                        }`}
                        onClick={() => setActiveTab(index)}
                    />
                ))}
            </div>
            <Button
                className={
                    "bg-black text-white mt-3  xs:text-base xs:mt-5 !rounded-[3rem] w-full"
                }
                onClick={() => handleTab(activeTab + 1)}
            >
                Continue
            </Button>
            <div className="flex items-center justify-end mb-5">
                <P className="flex items-center gap-0.2">
                    Already have account?
                    <span
                        className="text-blue underline"
                        role="button"
                        onClick={() => history.push(`/lease${LOGIN}`)}
                    >
                        Sign in
                    </span>
                </P>
            </div>
        </div>
    );
};

export default WelcomePages;
