import React, { useState } from "react";
import P from "../../../shared/typography/P";

const MembersTable = () => {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <div className="lg:bg-background lg:p-2 lg:rounded-t-2 lg:shadow-sm">
            <div className="flex justify-between border-b border-blue-50 ">
                {["Members Details", "Investment Amount"].map((tab, i) => (
                    <div
                        key={i}
                        onClick={() => setActiveTab(i)}
                        className={`flex  hover:cursor-pointer items-center border-b-2 gap-1 pb-1 font-vietnam ${
                            activeTab === i
                                ? "text-blue-500  border-b-blue-500"
                                : "text-gray-500 border-b-transparent"
                        }`}
                    >
                        <P
                            className={`text-nowrap lg:text-sm xl:text-sm ${
                                activeTab === i
                                    ? "text-blue-500 "
                                    : "text-gray-500"
                            }`}
                        >
                            {tab}
                        </P>
                    </div>
                ))}
            </div>
            <div className="overflow-y-auto max-h-screen  lg:max-h-[600px]">
                {[...Array(20).keys()].map((item) => (
                    <div className="border-b border-gray-400 py-1.6 flex justify-between">
                        <div>
                            <P className="text-gray font-medium font-vietnam mb-0.5">
                                Aman Gupta
                            </P>
                            <P className="text-gray-550">C2 303</P>
                        </div>{" "}
                        <div>
                            <P className="text-gray font-medium font-vietnam mb-0.5">
                                ₹ 1,10,000
                            </P>
                            <P className="text-gray-550">15 Jan, 2025</P>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MembersTable;
