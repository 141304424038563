import React from "react";
import cn from "../../../lib/cn";
import {
    firstLeaseSlider,
    secondLeaseSlider,
} from "../../../resources/js/images";
import { leaseHome } from "../../../resources/js/videos";
import Section from "../../../shared/Section";
import ContactUSForm from "../../home/ContactUSForm";
import EstimateSavings from "../../home/EstimateSavings";
import Faq from "../../home/Faq";
import InvestNow from "../../home/InvestNow";
import PriceSection from "../../home/Sections/PriceSection";
import SrotBenefit from "../../home/SrotBenefit";
import LeaseContent from "./LeaseContent";
const cardData = [
    {
        id: 1,
        image: firstLeaseSlider,
        heading: "Select a pre-vetted and risk assessed project",
        text: "To get started, simply follow these steps: first, complete your profile by submitting your KYC details to verify your account. Next, load funds into your wallet to ensure you're ready to invest. Once your wallet is funded, explore and invest in available opportunities to begin growing your portfolio.",
    },
    {
        id: 2,
        image: secondLeaseSlider,

        heading: "Explore and Invest",
        text: "Dive into the Projects section to explore and choose from various open investment opportunities. All our projects are pre-vetted, risk-assessed, and pre-leased, ensuring you can invest confidently and enjoy higher returns with lower risk.",
    },
];
const LeaseHome = () => {
    return (
        <div>
            <Hero
                containerClass={
                    "mb-0 xl:mb-0 lg:mb-0 2xl:mb-0 bg-custom-gradient  "
                }
                wrapperClass=" md:mx-9 min-h-[5vh] sm:min-h-[5vh] md:max-h-[100vh]  xl:min-h-[100vh]"
                contentClass="mt-[5rem] md:pt-0 md:items-center"
                content={<LeaseContent />}
                url={leaseHome}
                type="video"
            />
            <SrotBenefit />

            <InvestNow cardData={cardData} />
            <EstimateSavings />
            <PriceSection />
            <ContactUSForm />
            <Faq />
        </div>
    );
};

export default LeaseHome;

const Hero = ({
    content,
    url,
    align,
    type,
    wrapperClass,
    containerClass,
    contentClass,
    videoClass,
}) => {
    return (
        <Section className={containerClass}>
            <div
                className={cn(
                    "relative  h-[593px]   md:h-[993px] lg:h-[603px]  xl:h-screen",
                    wrapperClass
                )}
            >
                <div
                    className={cn(
                        `absolute inset-x-0 inset-y-0  h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen flex isolate z-40`,
                        contentClass
                    )}
                >
                    {content}
                </div>
            </div>
            {type === "video" ? (
                <video
                    src={url}
                    className={cn(
                        "absolute inset-y-0 inset-x-0  h-[600px]  md:h-[993px] lg:h-[610px] xl:h-screen w-full object-cover",
                        videoClass
                    )}
                    autoPlay
                    loop
                    muted
                    style={{ zIndex: 2 }}
                />
            ) : (
                <img
                    style={{ zIndex: 2 }}
                    src={url}
                    className="absolute inset-y-0 inset-x-0 h-[593px]  md:h-[993px] lg:h-[603px] xl:h-screen w-full object-cover"
                    alt="hero"
                />
            )}
        </Section>
    );
};
