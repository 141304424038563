import React from "react";
import { submitInterest } from "../../../resources/js/images";
import Container from "../../../shared/Container";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import LabeledInput from "../../../shared/ui/Form/LabeledInput";

const SubmitContent = ({ onClick }) => {
    return (
        <Container className="  rounded-t-2">
            <div className="flex justify-center">
                <img
                    className="h-auto max-w-[400px]"
                    src={submitInterest}
                    alt="submit_interest"
                />
            </div>

            <H4 className="text-gray font-vietnam mt-2 text-sm 2xs:text-base font-medium">
                Ready to invest? Tell us your desired amount and we’ll Keep you
                updated
            </H4>
            <P className="mt-1.5 mb-2 text-xs  font-vietnam">
                We'll provide the payment link once enough community members
                express interest in investing, just like you.
            </P>

            <LabeledInput
                errorMessage=""
                wrapperClassName="mb-1.6"
                placeholder="48,320"
                label="Enter amount for investment"
            />
            <Button
                onClick={onClick}
                className="rounded-full bg-gray mt-2.8 w-full"
            >
                Submit Interest
            </Button>
        </Container>
    );
};

export default SubmitContent;
