import React from "react";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { LEASE_SUBMIT } from "../../../constants/routes";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import InvestmentCalculator from "../../home/ExistingUser/NewUser/InvestmentCalculator";

const data = [
    "Your investment will earn 19% returns from revenue generated by your solar plant",
    "Your society will save 38% on existing electricity bill",
    "Your investment are secured by rooftop solar plant installed on society premise",
];

const LeaseInterest = () => {
    const history = useHistory();
    return (
        <Section className="flex justify-center">
            <div className="max-w-[54rem]">
                <div className="bg-black-600 h-13 m-0 lg:hidden"></div>
                <div className="rounded-t-4 -mt-6 bg-white pt-4 lg:mt-5 xl:mt-10.4">
                    <Container className="  rounded-t-2">
                        <H4 className=" text-sm 2xs:text-base font-medium text-gray font-vietnam mb-1.6 lg:mb-3">
                            Estimate Your Return
                        </H4>

                        <InvestmentCalculator />
                    </Container>

                    <Container className="mt-2">
                        <P className=" mb-1.6 font-medium font-vietnam">
                            Invest together to get exclusive benefits
                        </P>

                        {data.map((item, index) => (
                            <div
                                key={index}
                                className="flex items-start gap-0.8 mb-1.6"
                            >
                                <P className=" text-xl mt-0.2">
                                    <IoMdCheckmarkCircle />
                                </P>
                                <P className="font-vietnam text-xs 2xs:text-sm font-light">
                                    {item}
                                </P>
                            </div>
                        ))}

                        <Button
                            onClick={() => history.push(LEASE_SUBMIT)}
                            className="w-full bg-blue-600 rounded-full mt-2.8 xl:mt-5"
                        >
                            Show Interest
                        </Button>
                    </Container>
                </div>
            </div>
        </Section>
    );
};

export default LeaseInterest;
