import React from "react";
import Container from "../../../shared/Container";
import BottomDrawer from "../../../shared/ui/Drawer/BottomDrawer";
import MembersTable from "./MembersTable";

const ViewDetailsInterest = ({ isOpen, onClose }) => {
    return (
        <BottomDrawer isOpen={isOpen} onClick={onClose}>
            <div className="w-full h-full bg-white rounded-t-2 pb-2 overflow-y-hidden">
                <Container>
                    <MembersTable />
                </Container>
            </div>
        </BottomDrawer>
    );
};

export default ViewDetailsInterest;
