import React from "react";
import { BsSend } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Faq from "../../home/Faq";
import FundDetails from "./FundDetails";
import InterestBanner from "./InterestBanner";
import MemberDetails from "./MemberDetails";
import MembersTable from "./MembersTable";
import PlantDetails from "./PlantDetails";

const data = [
    {
        id: 1,
        name: "Fund Required",
        value: "₹52,57,500",
    },
    {
        id: 2,
        name: "Investor’s Return",
        value: "20.5%",
    },
    {
        id: 3,
        name: "Society Savings",
        value: "40.11%",
    },
];
const LeaseDashboard = () => {
    return (
        <Section>
            <Container className="hidden lg:block">
                <div className="flex justify-between gap-2 2xl:gap-4 mt-10.4">
                    <div className="w-8/12 2xl:w-8/12">
                        {/* @TODO => left view @habib610 Wed February 12,2025 */}
                        <div className="bg-black-600 p-2.4 rounded-2 mb-4">
                            <div className="flex flex-wrap justify-between gap-y-2 ">
                                <div className=" w-full  xl:w-[42%] 2xl:w-[45%]">
                                    <PlantDetails />
                                </div>
                                <div className="w-full xl:w-[55%] 2xl:w-[50%]">
                                    <FundDetails
                                        label="Lease Plan Details"
                                        data={data}
                                    >
                                        <div className="flex items-center justify-between gap-2 mt-2">
                                            <Button className="flex-1 bg-gray flex items-center gap-1">
                                                Revise
                                                <P className="text-white text-base">
                                                    <FaEdit />
                                                </P>
                                            </Button>
                                            <Button className="bg-white text-gray flex-1">
                                                Invite{" "}
                                                <P className="text-gray text-base">
                                                    <BsSend />
                                                </P>
                                            </Button>
                                        </div>
                                    </FundDetails>
                                </div>
                            </div>
                        </div>

                        <Faq className="pt-0 xl:pt-5 rounded-t-2 shadow-md" />
                    </div>
                    <div className="w-4/12 2xl:w-4/12">
                        {/* @TODO => side bar @habib610 Wed February 12,2025 */}
                        <MemberDetails />
                        <InterestBanner />
                        <MembersTable />
                    </div>
                </div>
            </Container>

            {/* @TODO => desktop views @habib610 Wed February 12,2025 */}

            <div className="lg:hidden">
                <PlantDetails />
                <Container>
                    <FundDetails
                        label="Lease Plan Details"
                        className={"-mt-2.4"}
                        data={data}
                    >
                        <div className="flex items-center justify-between gap-2 mt-2">
                            <Button className="flex-1 bg-gray flex items-center gap-1">
                                Revise
                                <P className="text-white text-base">
                                    <FaEdit />
                                </P>
                            </Button>
                            <Button className="bg-white text-gray flex-1">
                                Invite{" "}
                                <P className="text-gray text-base">
                                    <BsSend />
                                </P>
                            </Button>
                        </div>
                    </FundDetails>
                </Container>

                <InterestBanner />
                <MemberDetails />
                <Faq />
            </div>
        </Section>
    );
};

export default LeaseDashboard;
