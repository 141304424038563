import React from "react";
import { signUp } from "../../resources/js/icons";
import P from "../../shared/typography/P";

const WelcomeScreen2 = () => {
    return (
        <div className="text-center w-full">
            <img
                src={signUp}
                alt="signup"
                className="w-full h-96 xs:h-full md:h-96 "
            />
            <P className="font-medium font-vietnam text-base xs:text-lg mt-5">
                Join Hand
            </P>
            <p className="text-xs xs:text-base md:text-sm font-vietnam mt-1 mb-2 xs:mb-5 md:text-gray-550">
                Sign up, register, and build a community with fellow owners to
                invest in the rooftop solar project installed on your premises.
            </p>
        </div>
    );
};

export default WelcomeScreen2;
