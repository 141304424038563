import React from "react";
import { useHistory } from "react-router-dom";
import { hi } from "../../resources/js/icons";
import H3 from "../../shared/typography/H3";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";

const WelcomeScreen1 = () => {
    const history = useHistory();
    return (
        <div className="flex-col w-full items-center  text-center">
            <div className="flex items-center justify-center">
                <img src={hi} alt="hi" className="w-8 h-8" />
            </div>
            <H3 className="font-medium">Mohar Pratima’s Resident</H3>

            <P className="font-medium font-vietnam text-base mt-5">
                Join the Community
            </P>
            <p className="text-xs font-vietnam mb-5">
                Welcome to our app! Connect with fellow flat owners to invest in
                rooftop solar installations. Together, you can contribute to a
                sustainable future and enjoy the benefits of solar energy.
            </p>

            <div className="flex justify-between items-cente bg-linear-blue-white p-1 rounded-2xl mb-5 ">
                <div className="flex-col text-center items-center justify-center">
                    <H4 className="text-white font-medium">60</H4>
                    <p className="text-sm text-white">Members Joined</p>
                </div>
                <div
                    className={`bg-gray-400 w-0.1 min-h-[5rem]  max-h-[10rem]
                `}
                />
                <div className="flex-col text-center items-center justify-center">
                    <H4 className="text-white font-medium">35%</H4>
                    <p className="text-sm text-white">Members want to Invest</p>
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen1;
