import React, { useState } from "react";
import Section from "../../../shared/Section";
import InterestConfirmation from "./InterestConfirmation";
import SubmitContent from "./SubmitContent";

const SubmitInterest = () => {
    const [confirmation, setConfirmation] = useState(false);
    return (
        <Section className="flex justify-center">
            <div className="max-w-[54rem]">
                {" "}
                <div className="bg-black-600 h-13 m-0 lg:hidden"></div>
                <div className="rounded-t-4 -mt-6 bg-white pt-4  lg:mt-5 xl:mt-10.4">
                    {confirmation ? (
                        <InterestConfirmation
                            onClick={() => setConfirmation(false)}
                        />
                    ) : (
                        <SubmitContent onClick={() => setConfirmation(true)} />
                    )}
                </div>
            </div>
        </Section>
    );
};

export default SubmitInterest;
