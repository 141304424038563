import React from "react";
import {
    FaFacebookSquare,
    FaInstagram,
    FaLinkedin,
    FaRegEnvelope,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiYoutube } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineAddIcCall } from "react-icons/md";

import { Link, useLocation } from "react-router-dom";
import {
    FORGET,
    LOGIN,
    SIGNUP,
    TERMS_N_CONDITIONS,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import cn from "../../lib/cn";
import { logoWhite } from "../../resources/js/images";
import Container from "../Container";
import P from "../typography/P";

const excludeFooter = [
    LOGIN,
    FORGET,
    SIGNUP,
    `/lease${LOGIN}`,
    `/lease/register`,
];

const Footer = () => {
    const { pathname } = useLocation();
    const { state } = useContextState();

    return (
        <footer
            className={cn("bottom-0 bg-gray ", {
                hidden: excludeFooter.includes(pathname) || state?.user,
            })}
        >
            <Container className="py-3.2">
                <div className="flex justify-between flex-wrap">
                    <div className="w-full xl:w-[20%]">
                        <img
                            src={logoWhite}
                            alt="Hypersrot_logo_white"
                            className="h-[28px]  xl:h-[35.36px]"
                        />
                        <P className="text-xs sm:text-lg 2xl:text-base text-white  mt-1.6 2xl:mt-2.4 mb-2.4 2xl:mb-5.6">
                            Our goal is to contribute towards India's green
                            energy goals by utilizing solar energy to meet
                            India's energy needs efficiently and cost
                            effectively.
                        </P>
                        <div className="flex justify-start w-[70%] gap-[2rem] ">
                            <a
                                href="https://www.instagram.com/hypersrot/"
                                className="text-xl text-white "
                            >
                                <FaInstagram />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/hypersrot/"
                                className="text-xl text-white"
                            >
                                <FaLinkedin />
                            </a>
                            <a
                                href="https://www.facebook.com/Hypersrot-101576895575440"
                                className="text-xl text-white"
                            >
                                <FaFacebookSquare />
                            </a>
                            <a
                                href="https://twitter.com/hypersrot"
                                className="text-xl text-white"
                            >
                                <FaXTwitter />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCahJwUMoHRSIC0IOnNKf0Yg"
                                className="text-xl text-white"
                            >
                                <FiYoutube />
                            </a>
                        </div>
                    </div>
                    <hr className="bg-white w-full my-2.4  xl:hidden" />
                    <div className="w-[45%] sm:w-auto xl:w-auto">
                        <P className="text-base sm:text-xl text-nowrap font-semibold  text-white mb-1.6  xl:mb-2.4">
                            Use Cases
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base mb-1.6 sm:mb-0.8 xl:mb-1.6  text-white">
                            Knowledge Hub
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base mb-1.6 sm:mb-0.8 xl:mb-1.6  text-white">
                            Pricing
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base  text-white">
                            Onboarding
                        </P>
                    </div>
                    <div className="w-[45%] sm:w-auto xl:w-auto">
                        <P className="text-base sm:text-xl text-nowrap font-semibold  text-white mb-1.6 xl:mb-2.4">
                            Company
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base mb-1.6 sm:mb-0.8 xl:mb-1.6  text-white">
                            Home
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base mb-1.6 sm:mb-0.8 xl:mb-1.6  text-white">
                            AboutUs
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base mb-1.6 sm:mb-0.8 xl:mb-1.6  text-white">
                            Contact us
                        </P>
                        <P className="text-sm sm:text-lg xl:text-base   text-white">
                            Login/Signup
                        </P>
                    </div>

                    <hr className="bg-white w-full my-2.4 sm:hidden " />

                    <div className="sm:w-[45%] xl:w-[50%]">
                        <div className="flex flex-wrap xsm:justify-between xl:justify-start mr-2 xsm:mr-0">
                            <div className="flex items-center xl:mr-9.6">
                                <FooterIcon>
                                    <MdOutlineAddIcCall className="text-white text-xl md:text-xl  " />
                                </FooterIcon>

                                <div className="w-full xsm:w-auto">
                                    <P className="text-xs sm:text-sm xl:text-base text-white">
                                        Give us a call
                                    </P>
                                    <P className="text-sm sm:text-base text-white font-semibold mt-0.4">
                                        +91 9970079570
                                    </P>
                                </div>
                            </div>
                            <div className="flex items-center mt-1.6 xsm:mt-0">
                                <FooterIcon>
                                    <FaRegEnvelope className="text-white text-xl md:text-xl lg:text-xl" />
                                </FooterIcon>

                                <div className="sm:mt-1.6">
                                    <P className="text-xs sm:text-sm xl:text-base text-white">
                                        Give us a email
                                    </P>
                                    <P className="text-sm sm:text-base text-white font-semibold mt-0.4">
                                        invest@srot.app
                                    </P>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-start mt-1.6 xl:mt-4">
                                <FooterIcon>
                                    {" "}
                                    <P className="text-white text-xl md:text-xl lg:text-xl  ">
                                        <IoLocationOutline />
                                    </P>
                                </FooterIcon>

                                <div className="flex flex-col">
                                    <div className="flex flex-col lg:flex-row mb-1.6">
                                        <P className="text-sm sm:text-sm xl:text-base  2xl:text-base font-semibold text-white xl:min-w-[150px]">
                                            Pune Office
                                        </P>
                                        <P className="text-xs sm:text-sm xl:text-base 2xl:text-base  text-white text-wrap">
                                            Hypersrot Technologies, Office No 6,
                                            3rd Floor , Tulsi Green Balewadi
                                            High Street Road, Laxman Nagar,
                                            Pune, Maharashtra, 411045
                                        </P>
                                    </div>
                                    <div className="flex flex-col lg:flex-row ">
                                        <P className="text-sm sm:text-sm xl:text-base  2xl:text-base font-semibold text-white xl:min-w-[150px] ">
                                            Bangalore Office
                                        </P>
                                        <P className="text-xs sm:text-sm xl:text-base 2xl:text-base  text-white text-wrap">
                                            Hypersrot Technologies Private
                                            Limited, 1207/343/95H, HSR MAIN
                                            ROAD, 7TH SECTOR, HSR BANGALORE
                                            560102
                                        </P>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="text-white my-2.4 xl:my-4 2xl:my-4.8" />
                <div className="flex flex-col items-center gap-1.6 xl:flex-row justify-between ">
                    <P className="text-sm sm:text-xxl xl:text-base 2xl:text-base text-white  ">
                        Copyright © 2021 Hypersrot Technologies Pvt Ltd. All
                        Rights Reserved
                    </P>
                    <div className="flex justify-end gap-4 md:gap-8">
                        <Link
                            to={TERMS_N_CONDITIONS}
                            className="text-xs md:text-xl xl:text-base 2xl:text-base text-white "
                        >
                            Term & Conditions
                        </Link>
                        <Link
                            to={TERMS_N_CONDITIONS + "/policy"}
                            className="text-xs md:text-xl xl:text-base 2xl:text-base text-white "
                        >
                            Privacy Policy
                        </Link>
                        <P className="text-xs md:text-xl xl:text-base 2xl:text-base text-white">
                            Site Map
                        </P>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;

const FooterIcon = ({ children }) => {
    return (
        <div className="flex items-center justify-center aspect-square bg-blue-700 rounded-full  lg:text-xl mr-1.6 p-0.8">
            {children}
        </div>
    );
};
