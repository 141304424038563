import { ROLE } from "../constants/common";
import { USER_HOME } from "../constants/routes";

import { checkIsAllowed } from "./helpers";

export const handleRedirection = (state, history, roles, status, type) => {
    if (type) {
        history.push("/lease/summary");
    } else if (state.redirectPath) {
        history.push(state.redirectPath);
    } else if (
        checkIsAllowed(roles, [
            ROLE.SUPER_ADMIN,
            ROLE.SALES_MANAGER,
            ROLE.OPERATION_MANAGER,
        ])
    ) {
        history.push(USER_HOME);
    } else {
        history.push(USER_HOME);
    }
};
