import React from "react";
import { useHistory } from "react-router-dom";
import { LEASE_INTEREST } from "../../../constants/routes";
import Container from "../../../shared/Container";
import H1 from "../../../shared/typography/H1";
import H2 from "../../../shared/typography/H2";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import FundDetails from "../dashboard/FundDetails";
const data = [
    {
        id: 1,
        name: "Fund Required",
        value: "₹52,57,500",
    },
    {
        id: 2,
        name: "Investor’s Return",
        value: "20.5%",
    },
    {
        id: 3,
        name: "Society Savings",
        value: "40.11%",
    },
];
const LeaseContent = () => {
    const history = useHistory();
    return (
        <div className=" w-full">
            <Container className="h-full">
                <div className="h-full flex flex-col justify-center">
                    <div className=" flex justify-center flex-col items-center">
                        <H2 className="text-xxl 2xs:text-3xl  text-center text-white font-sora mt-[100px] 2xs:mt-[170px] lg:mt-0 ">
                            Small Contributions
                        </H2>
                        <H1 className=" text-xxl 2xs:text-3xl w-full text-center text-white font-sora font-bold mb-1.6 lg:mb-3 xl:mb-4">
                            Big Returns
                        </H1>
                        <P className="text-white  text-center ">
                            Join your community in investing in rooftop solar
                            panels and start earning impressive returns.
                        </P>

                        <Button
                            className="mt-3 xl:mt-4"
                            onClick={() => history.push(LEASE_INTEREST)}
                        >
                            Show Interest
                        </Button>

                        <div className="w-full flex justify-between p-1.6 rounded-sm lg:mt-6 xl:mt-10.4 ">
                            <div>
                                <H3 className="text-white text-sm  2xs:text-base font-medium font-vietnam ">
                                    15 Members
                                </H3>
                                <P className="text-xs 2xs:text-sm font-vietnam text-white">
                                    Shown Interest
                                </P>
                            </div>
                            <div className="w-px bg-white h-auto lg:hidden"></div>
                            <div className="hidden lg:block  lg:w-7/12 xl:w-6/12">
                                <FundDetails className="w-full" data={data} />
                            </div>
                            <div>
                                <H3 className="text-white  text-sm  2xs:text-base font-vietnam font-medium ">
                                    26 Lakhs
                                </H3>
                                <P className=" text-xs 2xs:text-sm font-vietnam text-white">
                                    Wants to Invest
                                </P>
                            </div>
                        </div>
                        <div className="lg:hidden">
                            <FundDetails data={data} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LeaseContent;
