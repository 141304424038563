import React, { useCallback, useEffect, useState } from "react";
import OTPBOX from "react-otp-input";
import P from "../typography/P";

const COUNTER_TIME = 30;

const OTP = ({
    otp,
    onChange,
    error,
    errorMessage,
    handleResentOTP,
    label = "Enter OTP to verify phone number",
    showResentOtp = true,
}) => {
    const [showResendLink, setShowResendLink] = useState(true);
    const [countdown, setCountdown] = useState(COUNTER_TIME);
    const [resendCount, setResendCount] = useState(1);

    const onChangeHandler = useCallback(
        (value) => {
            onChange({
                target: {
                    name: "otp",
                    value: value,
                },
            });
        },
        [onChange]
    );

    useEffect(() => {
        if (showResendLink && countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (countdown === 0) {
            setShowResendLink(false);
        }
    }, [showResendLink, countdown]);
    return (
        <div className="flex flex-col gap-1.6">
            {label && (
                <P
                    className={
                        "text-gray-secondary mt-1 text-sm sm:text-base xl:text-lg"
                    }
                >
                    Enter OTP to verify phone number
                </P>
            )}
            <OTPBOX
                value={otp}
                onChange={onChangeHandler}
                numInputs={6}
                isInputNum={true}
                containerStyle="flex justify-between gap-0.5   box-border"
                focusStyle="border border-secondary"
                inputStyle={`line-height[1.6] text-base md:text-xl xl:text-3xl 2xl:text-5xl border border-solid  ${
                    error ? "border-red" : "border-blue-500"
                }  bg-white rounded-xl p-0.7   sm:py-1.4 xl:py-1.4  !w-[4rem] sm:!w-[6rem] box-border `}
            />
            {errorMessage && (
                <span className="text-red  text-sm xl:text-base">
                    {errorMessage}*
                </span>
            )}
            {showResentOtp && resendCount <= 3 && (
                <>
                    {showResendLink ? (
                        <div>
                            <p className="underline text-primary text-xs md:text-tag">
                                Resend OTP (in <span>{countdown}</span> sec)
                            </p>
                        </div>
                    ) : (
                        <div className="flex justify-between items-center">
                            <div
                                onClick={() => {
                                    setCountdown(
                                        COUNTER_TIME * (resendCount + 1)
                                    );
                                    setResendCount((count) => count + 1);
                                    setShowResendLink(true);
                                    handleResentOTP("voice");
                                }}
                                className="cursor-pointer"
                            >
                                <p className="underline text-blue text-xs md:text-sm">
                                    OTP By Call
                                </p>
                            </div>
                            <div
                                onClick={() => {
                                    setCountdown(
                                        COUNTER_TIME * (resendCount + 1)
                                    );
                                    setResendCount((count) => count + 1);
                                    setShowResendLink(true);
                                    handleResentOTP("text");
                                }}
                                className="cursor-pointer"
                            >
                                <p className="underline text-blue text-xs md:text-sm">
                                    OTP By Text
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default OTP;
