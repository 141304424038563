import React, { useState } from "react";

import cn from "../../../lib/cn";
import { streamLine } from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import ViewDetailsInterest from "./ViewDetailsInterest";

const MemberDetails = () => {
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    return (
        <>
            <div className="bg-black-600 mb-2 py-3.2 lg:rounded-2 shadow-sm">
                <Container className="lg:px-1">
                    <div className="p-1.6 rounded-sm bg-black-400">
                        <div className="flex lg:w-full items-center gap-1.6 mb-2 bg-black-600 py-1.6 px-1 rounded-sm">
                            <div className="h-4">
                                <img src={streamLine} alt="stream_line" />
                            </div>
                            <div className="flex justify-between lg:w-full items-center gap-0.2 xl:gap-[10rem] flex-1 ">
                                <div>
                                    <P className=" font-vietnam lg:text-nowrap lg:text-sm  xl:text-xs text-gray-550 text-xs">
                                        Interest shown
                                    </P>
                                    <P className=" font-vietnam text-white text-sm lg:text-sm  xl:text-sm font-medium">
                                        15 members
                                    </P>
                                </div>
                                <div>
                                    <P className=" font-vietnam text-xs lg:text-nowrap lg:text-sm  xl:text-xs text-gray-550">
                                        Wants to invest
                                    </P>
                                    <P className=" font-vietnam text-white text-sm  lg:text-sm  xl:text-sm font-medium">
                                        ₹ 45 Lakhs
                                    </P>
                                </div>
                            </div>
                        </div>
                        <div className="w-full h-3 bg-white  rounded-3xl relative mb-2">
                            <div
                                className={cn(
                                    ` h-full rounded-3xl bg-linear-blue-white`
                                )}
                                style={{
                                    width: `${40}%`,
                                }}
                            ></div>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <div className="flex items-center ">
                                <div className="w-2 h-2  bg-gray-400  rounded-0.7 mr-1"></div>
                                <H4 className="text-white font-medium text-sm md:text-base xl:text-base  font-vietnam">
                                    Total Funds Required
                                </H4>
                                <H4 className="text-white ml-auto text-sm md:text-lg xl:text-lg font-vietnam">
                                    ₹ {3999}
                                </H4>
                            </div>
                            <div className="flex items-center">
                                <div className="w-2 h-2 bg-blue  rounded-0.7 mr-1"></div>
                                <H4 className="text-white text-sm font-medium md:text-base xl:text-base font-vietnam">
                                    Interest Raise
                                </H4>
                                <H4 className="text-white text-sm ml-auto md:text-lg xl:text-lg font-vietnam">
                                    ₹ {4000}
                                </H4>
                            </div>
                        </div>
                        <Button
                            onClick={() => setShowDetailsModal(true)}
                            className="text-gray bg-white w-full mt-2 lg:hidden"
                        >
                            View Details
                        </Button>
                    </div>
                </Container>
            </div>
            <ViewDetailsInterest
                isOpen={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
            />
        </>
    );
};

export default MemberDetails;
